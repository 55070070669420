/* View.css - Styles for the view processed files component */

.container {
  padding: var(--spacing-lg);
  max-width: 1200px;
  margin: auto;
  background-color: var(--background-dark);
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
}

/* Navigation */
nav {
  display: flex;
  justify-content: flex-start;
  margin-bottom: var(--spacing-lg);
}

/* Job listing area */
.job-list-container {
  margin-top: var(--spacing-lg);
}

.job-list-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--spacing-md);
}

.job-list-header h2 {
  margin: 0;
}

/* Jobs table */
.jobs-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: var(--spacing-lg);
}

.jobs-table th {
  background-color: var(--background-light);
  color: var(--secondary);
  font-weight: bold;
  text-align: center;
  padding: var(--spacing-md);
  border: 1px solid var(--border-color);
}

.jobs-table td {
  padding: var(--spacing-md);
  border: 1px solid var(--border-color);
  vertical-align: middle;
}

.jobs-table tr:hover {
  background-color: rgba(0, 116, 217, 0.1);
}

/* Job ID column */
.job-id {
  font-weight: bold;
  color: var(--secondary);
}

/* Timestamp column */
.timestamp {
  white-space: nowrap;
  color: var(--text-muted);
}

/* Filename column */
.filename {
  word-break: break-word;
  max-width: 200px;
}

/* Progress column */
.progress-details {
  text-align: center;
}

.progress-details hr {
  margin: var(--spacing-xs) 0;
  border-color: var(--border-color);
}

/* Cost column */
.cost {
  font-weight: bold;
  color: var(--secondary);
}

/* Download buttons column */
.download-buttons {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
}

.download-button {
  background-color: var(--primary);
  color: white;
  border: none;
  border-radius: 4px;
  padding: 6px 12px;
  cursor: pointer;
  font-size: 0.9rem;
  transition: background-color 0.3s;
  margin: 0;
}

.download-button:hover {
  background-color: var(--primary-dark);
}

.transcription-button {
  background-color: #4a90e2;
}

.translation-button {
  background-color: #50b45e;
}

.srt-button {
  background-color: #d88c2a;
}

/* Delete button */
.delete-button {
  background-color: var(--error);
  color: white;
  border: none;
  border-radius: 4px;
  padding: 6px 12px;
  cursor: pointer;
  font-size: 0.9rem;
  transition: background-color 0.3s;
}

.delete-button:hover {
  background-color: #e74c3c;
}

/* Pagination controls */
.pagination-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--spacing-md);
  margin-top: var(--spacing-lg);
}

.pagination-button {
  background-color: var(--background-light);
  color: var(--text-light);
  border: 1px solid var(--primary);
  border-radius: 4px;
  padding: 6px 12px;
  cursor: pointer;
  font-size: 0.9rem;
  transition: all 0.3s;
}

.pagination-button:hover:not(:disabled) {
  background-color: var(--primary);
}

.pagination-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.pagination-info {
  color: var(--text-muted);
}

/* Loading indicator */
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: var(--spacing-lg) 0;
  color: var(--primary);
  font-size: 1.2rem;
}

.loading::after {
  content: " ";
  width: 20px;
  height: 20px;
  margin-left: 15px;
  border: 5px solid var(--primary);
  border-radius: 50%;
  border-top-color: transparent;
  animation: loading-spin 1s linear infinite;
}

@keyframes loading-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Error message */
.error {
  background-color: rgba(255, 107, 107, 0.1);
  color: var(--error);
  padding: var(--spacing-md);
  border: 1px solid var(--error);
  border-radius: 5px;
  margin: var(--spacing-md) 0;
}

/* Refresh button */
.refresh-button {
  background-color: var(--primary);
  display: flex;
  align-items: center;
  gap: 8px;
}

.refresh-button:hover {
  background-color: var(--primary-dark);
}

.refresh-icon {
  font-size: 1rem;
}

/* Empty state */
.empty-state {
  text-align: center;
  margin: var(--spacing-xl) 0;
  color: var(--text-muted);
}

/* Responsive adjustments */
@media (max-width: 1200px) {
  .container {
    max-width: 95%;
  }
}

@media (max-width: 992px) {
  .jobs-table {
    display: block;
    overflow-x: auto;
  }
}

@media (max-width: 768px) {
  .jobs-table th,
  .jobs-table td {
    padding: var(--spacing-sm);
    font-size: 0.9rem;
  }

  .download-buttons {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .download-button,
  .delete-button {
    padding: 4px 8px;
    font-size: 0.8rem;
  }
}
