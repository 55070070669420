/* Login.css - Styles for the login component */

.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: var(--background-dark);
  padding: var(--spacing-lg);
}

.login-card {
  background-color: var(--background-light);
  border-radius: 8px;
  padding: var(--spacing-xl);
  width: 100%;
  max-width: 400px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
  animation: fadeIn 0.5s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.login-logo {
  text-align: center;
  margin-bottom: var(--spacing-lg);
}

.login-logo img {
  max-width: 150px;
  height: auto;
}

.login-title {
  color: var(--secondary);
  font-size: 1.8rem;
  text-align: center;
  margin-bottom: var(--spacing-lg);
}

.login-form {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
}

.form-group label {
  font-weight: bold;
  color: var(--text-light);
}

.login-input {
  padding: 12px;
  border-radius: 4px;
  border: 1px solid var(--border-color);
  background-color: var(--background-dark);
  color: var(--text-light);
  font-size: 1rem;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.login-input:focus {
  outline: none;
  border-color: var(--primary);
  box-shadow: 0 0 0 2px rgba(0, 116, 217, 0.2);
}

.login-input::placeholder {
  color: var(--text-muted);
}

.login-button {
  margin-top: var(--spacing-lg);
  padding: 12px;
  background-color: var(--primary);
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}

.login-button:hover {
  background-color: var(--primary-dark);
  transform: translateY(-2px);
}

.login-button:active {
  transform: translateY(0);
}

.error-message {
  color: var(--error);
  background-color: rgba(255, 107, 107, 0.1);
  padding: var(--spacing-sm);
  border-radius: 4px;
  margin-top: var(--spacing-sm);
  text-align: center;
}

.forgot-password {
  text-align: center;
  margin-top: var(--spacing-md);
}

.forgot-password a {
  color: var(--primary);
  text-decoration: none;
  font-size: 0.9rem;
  transition: color 0.3s;
}

.forgot-password a:hover {
  color: var(--secondary);
  text-decoration: underline;
}

/* Responsive adjustments */
@media (max-width: 480px) {
  .login-card {
    padding: var(--spacing-lg);
  }

  .login-title {
    font-size: 1.5rem;
  }

  .login-input {
    padding: 10px;
  }
}
