body {
  margin: 0;
  font-family: 'Arial', sans-serif;
  background-color: #001f3f;
  color: #ffffff;
}

.App {
  text-align: center;
  padding: 20px;
}

h1, h2 {
  color: #ffdc00;
}

form {
  margin-bottom: 20px;
}

input[type="file"] {
  display: block;
  margin: 10px auto;
  padding: 10px;
  background-color: #0074d9;
  border: none;
  color: #ffffff;
  cursor: pointer;
}

input[type="file"]::-webkit-file-upload-button {
  background: #001f3f;
  border: 1px solid #0074d9;
  color: #ffffff;
  padding: 10px;
  cursor: pointer;
}

input[type="checkbox"] {
  transform: scale(3); /* Increase checkbox size */
  margin: 0;
  padding: 0;
  display: block; /* Ensure it's treated as a block element */

}

select {
  margin: 10px;
  padding: 10px;
  background-color: #0074d9;
  border: none;
  color: #ffffff;
  cursor: pointer;
}

button {
  padding: 10px 20px;
  background-color: #0074d9;
  border: none;
  color: #ffffff;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
  margin: 10px;
}

button:hover {
  background-color: #005bb5;
}

.response {
  background-color: #003366;
  padding: 10px;
  margin: 10px 0;
  border-radius: 5px;
  text-align: left;
}


/* Add this CSS to increase horizontal spacing between table rows */
tbody tr {
  margin-bottom: 10px; /* Adjust spacing between rows */
}

/* Adjust padding and margin for table cells */
table td, table th {
  padding: 30px;
  text-align: center;
}

table {
  margin: 0 auto; /* Auto margins horizontally center the table */

}

.spinner {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loading-icon {
  display: flex;
  align-items: center;
  gap: 10px;
}


table {
  border-collapse: collapse;
  width: 100%;
}

th, td {
  border: 2px solid white; /* Black border */
  padding: 8px;
  text-align: left; /* Align text to the left */
}
/* 

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh; 
}

.button {
  margin: 10px;
  padding: 10px 20px;
  font-size: 16px; 
}


/* App.css - Main application styles */
:root {
  /* Color palette */
  --primary: #0074d9;
  --primary-dark: #005bb5;
  --secondary: #ffdc00;
  --background-dark: #001f3f;
  --background-darker: #001426;
  --background-light: #002b53;
  --text-light: #ffffff;
  --text-muted: #7c9ac0;
  --error: #ff6b6b;
  --success: #4caf50;
  --border-color: #003366;

  /* Spacing */
  --spacing-xs: 5px;
  --spacing-sm: 10px;
  --spacing-md: 15px;
  --spacing-lg: 20px;
  --spacing-xl: 30px;
}

/* Global styles */
body {
  margin: 0;
  font-family: 'Arial', sans-serif;
  background-color: var(--background-dark);
  color: var(--text-light);
  line-height: 1.6;
}

.App {
  text-align: center;
  padding: var(--spacing-lg);
  max-width: 1200px;
  margin: 0 auto;
}

/* Typography */
h1, h2, h3, h4, h5, h6 {
  color: var(--secondary);
  margin-top: var(--spacing-lg);
  margin-bottom: var(--spacing-md);
}

h1 {
  font-size: 2.2rem;
}

h2 {
  font-size: 1.8rem;
}

p {
  margin-bottom: var(--spacing-md);
}

/* Form elements */
input[type="file"] {
  display: block;
  margin: var(--spacing-sm) auto;
  padding: var(--spacing-sm);
  background-color: var(--primary);
  border: none;
  color: var(--text-light);
  cursor: pointer;
  border-radius: 5px;
  width: 100%;
  max-width: 400px;
}

input[type="file"]::-webkit-file-upload-button {
  background: var(--background-dark);
  border: 1px solid var(--primary);
  color: var(--text-light);
  padding: var(--spacing-sm);
  cursor: pointer;
  border-radius: 4px;
  margin-right: var(--spacing-sm);
  transition: background-color 0.3s;
}

input[type="file"]::-webkit-file-upload-button:hover {
  background-color: var(--background-light);
}

input[type="text"],
input[type="password"],
select,
textarea {
  padding: var(--spacing-sm);
  background-color: var(--background-light);
  border: 1px solid var(--primary);
  border-radius: 5px;
  color: var(--text-light);
  font-size: 1rem;
  margin: var(--spacing-sm) 0;
  width: 100%;
  box-sizing: border-box;
}

input[type="text"]:focus,
input[type="password"]:focus,
select:focus,
textarea:focus {
  outline: none;
  border-color: var(--secondary);
  box-shadow: 0 0 0 2px rgba(255, 220, 0, 0.2);
}

input[type="checkbox"] {
  transform: scale(1.5);
  margin: 0;
  padding: 0;
  cursor: pointer;
  accent-color: var(--primary);
}

select {
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='white'%3e%3cpath d='M7 10l5 5 5-5z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 20px;
  padding-right: 40px;
}

form {
  margin-bottom: var(--spacing-lg);
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

/* Buttons */
button {
  padding: var(--spacing-sm) var(--spacing-lg);
  background-color: var(--primary);
  border: none;
  color: var(--text-light);
  cursor: pointer;
  font-size: 1rem;
  border-radius: 5px;
  margin: var(--spacing-sm);
  transition: background-color 0.3s, transform 0.2s;
}

button:hover {
  background-color: var(--primary-dark);
  transform: translateY(-2px);
}

button:active {
  transform: translateY(0);
}

button:disabled {
  background-color: var(--background-light);
  cursor: not-allowed;
  opacity: 0.7;
  transform: none;
}

/* Tables */
table {
  border-collapse: collapse;
  width: 100%;
  margin: var(--spacing-lg) 0;
}

th, td {
  border: 1px solid var(--border-color);
  padding: var(--spacing-md);
  text-align: left;
}

th {
  background-color: var(--background-light);
  font-weight: bold;
}

tbody tr:hover {
  background-color: rgba(0, 116, 217, 0.1);
}

/* Spinner animation */
.spinner {
  animation: spin 1s linear infinite;
  color: var(--primary);
  font-size: 1.5rem;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loading-icon {
  display: flex;
  align-items: center;
  gap: var(--spacing-sm);
}

/* Container */
.container {
  padding: var(--spacing-lg);
  max-width: 1200px;
  margin: auto;
  background-color: var(--background-dark);
  border-radius: 8px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
}

/* Messages */
.error {
  background-color: rgba(255, 107, 107, 0.1);
  color: var(--error);
  padding: var(--spacing-md);
  border: 1px solid var(--error);
  border-radius: 5px;
  margin: var(--spacing-md) 0;
}

.success {
  background-color: rgba(76, 175, 80, 0.1);
  color: var(--success);
  padding: var(--spacing-md);
  border: 1px solid var(--success);
  border-radius: 5px;
  margin: var(--spacing-md) 0;
}

/* Progress bar */
progress {
  width: 100%;
  height: 10px;
  border-radius: 5px;
  overflow: hidden;
  margin: var(--spacing-xs) 0;
}

progress::-webkit-progress-bar {
  background-color: var(--background-light);
}

progress::-webkit-progress-value {
  background-color: var(--primary);
}

progress::-moz-progress-bar {
  background-color: var(--primary);
}

.progress-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

/* Response area */
.response {
  background-color: var(--background-light);
  padding: var(--spacing-md);
  margin: var(--spacing-md) 0;
  border-radius: 5px;
  text-align: left;
  border-left: 3px solid var(--primary);
}

/* Checkbox wrapper */
.checkbox-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .container {
    padding: var(--spacing-md);
  }
  
  table {
    font-size: 0.9rem;
  }
  
  th, td {
    padding: var(--spacing-sm);
  }
  
  button {
    padding: var(--spacing-sm) var(--spacing-md);
    font-size: 0.9rem;
  }
}