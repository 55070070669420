/* Uploader.css - Styles for the file uploader component */

.App {
  max-width: 900px;
  margin: auto;
  background-color: var(--background-dark);
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: var(--spacing-lg);
}

/* File selection area */
.file-input-container {
  margin: var(--spacing-lg) 0;
  padding: var(--spacing-lg);
  border: 2px dashed var(--primary);
  border-radius: 8px;
  background-color: var(--background-light);
  transition: all 0.3s ease;
}

.file-input-container:hover {
  border-color: var(--secondary);
  background-color: rgba(0, 116, 217, 0.1);
}

/* Model selection */
.model-selection {
  margin: var(--spacing-md) 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.model-selection label {
  font-weight: bold;
  margin-bottom: var(--spacing-xs);
  color: var(--secondary);
}

.model-selection select {
  width: 100%;
  max-width: 400px;
}

/* Prompt field styling */
.prompt-field {
  display: flex;
  flex-direction: column;
  margin-bottom: var(--spacing-md);
  width: 100%;
}

.prompt-field label {
  font-weight: bold;
  color: var(--secondary);
  margin-bottom: var(--spacing-xs);
  text-align: left;
}

.prompt-field textarea {
  width: 100%;
  padding: var(--spacing-md);
  background-color: var(--background-light);
  border: 1px solid var(--primary);
  border-radius: 5px;
  color: var(--text-light);
  font-family: Arial, sans-serif;
  font-size: 1rem;
  resize: vertical;
  min-height: 100px;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.prompt-field textarea:focus {
  outline: none;
  border-color: var(--secondary);
  box-shadow: 0 0 0 2px rgba(255, 220, 0, 0.2);
}

.prompt-field textarea::placeholder {
  color: var(--text-muted);
}

/* File options table */
.file-options-table {
  width: 100%;
  margin-top: var(--spacing-lg);
}

.file-options-table th {
  text-align: center;
  background-color: var(--background-light);
  color: var(--secondary);
}

.file-options-table td {
  vertical-align: middle;
}

/* File name styling */
.file-name {
  font-weight: bold;
  word-break: break-word;
}

/* Language input */
.language-input {
  width: 100%;
  max-width: 150px;
}

/* Progress indicators */
.progress-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.progress-container span {
  margin-bottom: var(--spacing-xs);
  font-weight: bold;
}

/* Action buttons */
.action-buttons {
  display: flex;
  justify-content: center;
  gap: var(--spacing-md);
  margin: var(--spacing-lg) 0;
}

.action-buttons button {
  min-width: 100px;
}

.start-button {
  background-color: var(--success);
}

.start-button:hover {
  background-color: #3d9c40;
}

.stop-button {
  background-color: var(--error);
}

.stop-button:hover {
  background-color: #e05d5d;
}

.clear-button {
  background-color: var(--background-light);
}

.clear-button:hover {
  background-color: #003366;
}

/* Checkbox styling */
.checkbox-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.checkbox-container input[type="checkbox"] {
  transform: scale(3.5);
}

/* Acknowledgment message */
.acknowledgment {
  font-size: 1.2rem;
  font-weight: bold;
  margin: var(--spacing-lg) 0;
  padding: var(--spacing-md);
  border-radius: 5px;
  text-align: center;
}

/* Navigation */
nav {
  display: flex;
  justify-content: flex-start;
  margin-bottom: var(--spacing-lg);
}

/* Responsive adjustments */
@media (max-width: 992px) {
  .file-options-table {
    font-size: 0.9rem;
  }

  .file-options-table th,
  .file-options-table td {
    padding: var(--spacing-sm);
  }

  .language-input {
    max-width: 100px;
  }
}

@media (max-width: 768px) {
  .file-options-table {
    display: block;
    overflow-x: auto;
  }

  .action-buttons {
    flex-direction: column;
    align-items: center;
  }

  .action-buttons button {
    width: 100%;
    max-width: 200px;
  }
}
