/* Home.css - Styles for the home component */

.home-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: var(--background-dark);
  padding: var(--spacing-lg);
}

.app-title {
  font-size: 2.5rem;
  color: var(--secondary);
  margin-bottom: var(--spacing-xl);
  text-align: center;
}

.app-subtitle {
  font-size: 1.2rem;
  color: var(--text-muted);
  margin-bottom: var(--spacing-xl);
  text-align: center;
  max-width: 600px;
}

#button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-lg);
  margin-top: var(--spacing-lg);
}

.button {
  width: 250px;
  height: 60px;
  font-size: 1.2rem;
  background-color: var(--primary);
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-sm);
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.button:hover {
  background-color: var(--primary-dark);
  transform: translateY(-3px);
  box-shadow: 0 7px 14px rgba(0, 0, 0, 0.1);
}

.button:active {
  transform: translateY(-1px);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}

.button-icon {
  font-size: 1.4rem;
}

.upload-button {
  background-color: var(--primary);
}

.view-button {
  background-color: var(--background-light);
  border: 2px solid var(--primary);
}

/* Login form */
.login-container {
  background-color: var(--background-light);
  border-radius: 8px;
  padding: var(--spacing-xl);
  width: 100%;
  max-width: 400px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
}

.login-title {
  text-align: center;
  color: var(--secondary);
  margin-bottom: var(--spacing-lg);
}

.login-form {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
}

.form-group label {
  font-weight: bold;
  color: var(--text-light);
}

.login-input {
  padding: 12px;
  border-radius: 4px;
  border: 1px solid var(--border-color);
  background-color: var(--background-dark);
  color: var(--text-light);
  font-size: 1rem;
}

.login-input:focus {
  outline: none;
  border-color: var(--primary);
  box-shadow: 0 0 0 2px rgba(0, 116, 217, 0.2);
}

.login-button {
  margin-top: var(--spacing-md);
  padding: 12px;
  background-color: var(--primary);
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.login-button:hover {
  background-color: var(--primary-dark);
}

/* Footer */
.footer {
  margin-top: auto;
  padding: var(--spacing-md);
  text-align: center;
  color: var(--text-muted);
  font-size: 0.9rem;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .app-title {
    font-size: 2rem;
  }

  .app-subtitle {
    font-size: 1rem;
  }

  .button {
    width: 220px;
    height: 50px;
    font-size: 1rem;
  }

  .login-container {
    padding: var(--spacing-lg);
  }
}

@media (max-width: 480px) {
  .app-title {
    font-size: 1.8rem;
  }

  .button {
    width: 200px;
  }
}
